<template>
  <default-page>
    <section-frame title="Recent Feed" subTitle="最新情報" bBack class="mt-6">
      <the-chamber-news-article :id="$route.params.id"></the-chamber-news-article>
      <v-btn
        color="white"
        class="ml-2 mt-1"
        rounded
        small
        outlined
        @click="$router.go(-1)"
        >back</v-btn
      >
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame'
import TheChamberNewsArticle from '@/components/TheChamberNewsArticle.vue'
export default {
  components: {
    DefaultPage,
    SectionFrame,
    TheChamberNewsArticle,
  },
}
</script>
