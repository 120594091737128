const news = [
  {
    id: '22113001',
    date: '2022-11-30',
    category: 'New Product',
    title: 'LAZY MASTER ~Lushly Green~発売！',
    src: require('@/assets/news/22113001.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/6385aa4e18324e2b0d582f31',
        title: 'オンラインストア',
      },
    ],
    contents: [
      [
        '新商品発売のお知らせです。</br></br>',
        '静岡県の西伊豆にある松崎町は塩漬けにした桜葉の名産地です。</br>',
        '第6作目となる〜Lushly Green〜は桜葉をキーボタニカルに使用し、',
        '県内産の緑茶、クロモジなど緑色のボタニカルを沢山使用しました。</br>',
        '今回の仙人は自然をこよなく愛する優しい老婆がモチーフです。</br></br>',
        '【商品の特徴】</br>',
        'ソーダ割や水割りにすると、桜餅のような香りと緑茶のキリッとした苦味、柚子やレモンの爽やかさも感じられます。',
        '和食にも合いますので食中酒としてもお愉しみいただけます。</br>',
        '原酒には沼津市内のクラフトビール醸造所「Repubrew」がクラフトジンのためだけに製造したベースアルコールを使用しています。',
        '<br/><br/>',

        'お求めは<a href="https://flavour.jp/shops/" style="color: rgb(143, 214, 255);">近隣のお取扱い酒販店様</a>、<a href="https://numazu-distillery.stores.jp/items/6385aa4e18324e2b0d582f31" style="color: rgb(143, 214, 255);">オンラインストア</a>、沼津蒸留所店頭をご利用ください！',
      ],
    ],
  }, 
  {
    id: '22100502',
    date: '2022-10-05',
    category: 'Restock',
    title: 'LAZY MASTER M4STERMIND (Original) 4本セット 再入荷のお知らせ',
    src: require('@/assets/news/22091402.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/624e85813463e724fcb21402',
        title: 'LAZY MASTER M4STERMIND Original 4本セット(オンラインストア)',
      },   
    ],
    contents: [
      [
        'ご好評につき完売となっていたLAZY MASTER M4STERMIND Original 4本セットが再入荷いたしました。</br>',
        '沼津蒸留所店頭、オンラインストア、お取り扱い酒販店様にてお求めいただけます。</br></br>',
        'M4STERMIND Original 4本セットはTOKYO WHISKY & SPIRITS COMPETITON 2022で金賞と銀賞を受賞した4種類のクラフトジンをミニボトルに入れたセットです。</br>',
        '飲み比べに最適な商品で、ギフトにもおススメです。</br>',
        'ぜひこの機会にお試しください。</br>',

      ],
    ],    
  },
  {
    id: '22100501',
    date: '2022-10-05',
    category: 'SOLD OUT',
    title: 'LAZY MASTER Heavenly Rose 500ml 欠品のお知らせ',
    src: require('@/assets/news/22100501.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/60a8a3bf1945c7184ddd1a52',
        title: 'LAZY MASTER Heavenly Rose 500ml(オンラインストア)',
      },
      {
        url: 'https://www.instagram.com/numazu_distillery/',
        title: 'Instagram(@numazu_distillery)',
      },
      {
        url: 'https://twitter.com/flavour_Ltd',
        title: 'twitter(@flavour_Ltd)',
      },         
    ],
    contents: [
      [
        'LAZY MASTER Heavenly Rose 500mlはご好評につき完売いたしまた。</br>',
        '沼津蒸留所店頭、オンラインストアの在庫はございませんが、一部お取り扱い酒販店様でまだ在庫がある場合がございます。</br>',
        '詳しい在庫状況は各お取り扱い酒販店様までお問い合わせください。</br></br>',
        'なお、次回の入荷時期は10月末頃の予定となっております。</br>',
        '再入荷の際はホームページにてお知らせするほか、インスタグラム、twitter等でも最新情報を発信しておりますのでご確認ください。'
      ],
    ],
  }, 
  {
    id: '22091402',
    date: '2022-09-14',
    category: 'SOLD OUT',
    title: 'LAZY MASTER M4STERMIND (Original) 4本セット 完売のお知らせ',
    src: require('@/assets/news/22091402.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/624e85813463e724fcb21402',
        title: 'LAZY MASTER M4STERMIND Original 4本セット(オンラインストア)',
      },
      {
        url: 'https://www.instagram.com/numazu_distillery/',
        title: 'Instagram(@numazu_distillery)',
      },
      {
        url: 'https://twitter.com/flavour_Ltd',
        title: 'twitter(@flavour_Ltd)',
      },      
    ],
    contents: [
      [
        'LAZY MASTER M4STERMIND Original 4本セットはご好評につき完売いたしまた。</br>',
        '沼津蒸留所店頭、オンラインストアの在庫はございませんが、一部お取り扱い酒販店様でまだ在庫がある場合がございます。</br>',
        '詳しい在庫状況は各お取り扱い酒販店様までお問い合わせください。</br></br>',
        'なお、次回の入荷時期は未定となっております。</br>',
        '再入荷の際はホームページにてお知らせするほか、インスタグラム、twitter等でも最新情報を発信しておりますのでご確認ください。'
      ],
    ],
  }, 
  {
    id: '22091401',
    date: '2022-09-14',
    category: 'Restock',
    title: 'Shiny Lemonngrass再入荷について',
    src: require('@/assets/news/22091401.png'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/61298598e49433245d34171a',
        title: 'LAZY MASTER Shiny Lemongrass(オンラインストア)',
      },
    ],
    contents: [
      [
        'ご好評につき欠品となっていたLAZY MASTER Shiny Lemonngrassが再入荷いたします。</br>',
        '9/16(金）午後に再入荷となり、沼津蒸留所店頭での販売は9/16(金)のTHE CHAMBER バータイム（17:00OPEN）からとなります。</br></br>',
        '',
        'ご予約の場合は16日より順次出荷となります。</br>大変多くのご予約をいただいており、ご注文いただいた順での発送となります。</br>お届けまで今しばらくお待ちください。',

      ],
    ],
  }, 
  {
    id: '22080801',
    date: '2022-08-08',
    category: 'News',
    title: 'お盆期間中の営業時間について',
    src: require('@/assets/news/22080801.jpg'),
    links: [
      {
        url: 'https://flavour.jp/img/22080801.jpg',
        title: 'お盆の予定 画像を拡大',
      },
    ],
    contents: [
      [
        'お盆期間中のバータイムは通常営業、らららサンビーチは毎日営業します！</br>',
        '暑い夏を冷たいジントニックで乗り切りましょう！！</br></br>',
        '水曜日のOnTheTerraceは8月中は夜の開催となります。</br>',
        '日が沈んだ後の夜の川沿いでおいしいコーヒーもどうぞ。</br></br>',
        'ランチタイムは不規則な営業となりご迷惑をおかけします。</br> 何卒宜しくお願い致します。</br>'
      ],
    ],
  },  
  {
    id: '22072901',
    date: '2022-07-29',
    category: 'News',
    title: 'Nutty Strawberry再入荷しました',
    src: require('@/assets/news/22072901.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/6257d16ac9883d7616d60895',
        title: 'オンラインストア',
      },
    ],
    contents: [
      [
        '伊豆半島の伊豆市江間で取れたイチゴをボタニカルに使用したLAZY MASTER第5段のNutty Strawberryは完売となっておりましたが、本日再入荷いたしました。</br></br>',
        '商品のお求めはお取り扱い酒販店、沼津蒸留所店頭またはオンラインストア、をご利用ください。</br><Br/>',
        '沼津蒸留所併設のカフェ・バー THE CHAMBER（ザ チェンバー)では１杯からクラフトジンをお楽しみいただけます。</br>',
        '17:00-21:00（日曜定休）で営業しておりますのでこちらもぜひお越しください。'
      ],
    ],
  },  
  {
    id: '22072801',
    date: '2022-07-28',
    category: 'News',
    title: '花火大会の営業について',
    src: require('@/assets/news/22072801.jpg'),
    links: [
      {
        url: 'https://www.instagram.com/the_chamber_numazu/',
        title: 'THE CHAMBER Instagram',
      },
    ],
    contents: [
      [
        '7/30、31は沼津夏祭りの花火大会ですがTHE CHAMBERのバータイム営業は臨時休業とさせていただきます。</br></br>',
        '店の前からは花火が見える絶好のロケーションなのですが、下記の事情のための営業を行うことは困難と判断いたしました。</br></br>',
        '・花火の当日は狩野川沿いの道路は歩行者も含めて通行止めとなり、桟敷席の予約者のみが入場ゲート通れます。そのため、花火の桟敷席のご予約がない一般のお客様が店舗にたどり着くことはできません。</br></br>',
        '・コロナ感染防止対策として、今年の桟敷席は飲食の自粛を求められています。当店としても桟敷のお客様向けの酒類などの提供は控えることとなりました。</br></br>',
        '3年ぶりの花火大会の開催は大変嬉しいニュースですが、当店で花火を見ようとご計画いただいていた皆様にはご期待に沿えず大変申し訳ございません。</br>',
        '来年こそはコロナも落ち着いて、皆様と花火を見ながら乾杯ができることを願っています。</br></br>',
        'なお、通行止めとなるのは17:00以降となります。それまでの日中の営業につきましては現在検討中ですので、営業を行う場合はinstagramの投稿またはストーリーにてお知らせいたします。',
      ],
    ],
  },
  {
    id: '22072301',
    date: '2022-07-23',
    category: 'News',
    title: 'らららサンビーチ出店中！',
    src: require('@/assets/news/22072301.jpg'),
    links: [
      // {
      //   url: 'https://tokyowhiskyspiritscompetition.jp/',
      //   title: 'Tokyo Whisky and Spirits Competition',
      // },
      // {
      //   url: 'https://numazu-distillery.stores.jp/',
      //   title: 'オンラインストア',
      // },
    ],
    contents: [
      [
        '7/16から8/21までの間、沼津市平沢にのらららサンビーチに移動式GIN BAR TRUCKが出店します。</br></br>',
        '静岡県唯一のクラフトジンの蒸留所がつくる、クラフトジンを使ったジントニック、ジンソーダなどのカクテルをお楽しみいただけます！</br>',
        '沼津を代表するブリュワリーの一つであるRepubrewのクラフトビールもご用意しています!</br>',
        'カラフルなノンアルカクテル、スムージーもご用意していますのでお車の方も是非お越しください！</br>',
      ],
    ],
  },
  {
    id: '22071601',
    date: '2022-07-16',
    category: 'News',
    title: 'GIN BAR TRUCK の営業を開始しました',
    src: require('@/assets/news/22071601.jpg'),
    links: [
      // {
      //   url: 'https://tokyowhiskyspiritscompetition.jp/',
      //   title: 'Tokyo Whisky and Spirits Competition',
      // },
      // {
      //   url: 'https://numazu-distillery.stores.jp/',
      //   title: 'オンラインストア',
      // },
    ],
    contents: [
      [
        '沼津蒸留所はバートラックを製作し、営業を開始しました。</br>',
        '飲食店営業許可を取得済みで、マルシェやフェスなどのイベントでクラフトジンを使ったカクテルをご提供することが可能です。</br>',
        '楽しい空間で、気の合う仲間とおいしいお酒を飲む機会を作っていきたいと考えています。</br></br>',
        '夏休み期間中は沼津市平沢のらららサンビーチに浜辺のバーとして登場するほか、7/24の堂ヶ島花火大会、8/5の週末の沼津に出店予定です。</br>',

        '出店のご依頼もおまちしております。</br>',
        '詳しくは下記リンクの問い合わせフォームよりご連絡ください。</br>',
        '<span style="font-size:0.8rem;">※開催場所や出店条件に寄っては出店できない場合もございますので予めご了承ください。</span>',
      ],
    ],
  },
  {
    id: '22051901',
    date: '2022-05-19',
    category: 'Prize',
    title: 'TWSC2022 金賞受賞!!',
    src: require('@/assets/news/22051901.jpg'),
    links: [
      {
        url: 'https://tokyowhiskyspiritscompetition.jp/',
        title: 'Tokyo Whisky and Spirits Competition',
      },
      {
        url: 'https://numazu-distillery.stores.jp/',
        title: 'オンラインストア',
      },
    ],
    contents: [
      [
        'アジア最大の蒸留酒の品評会である',
        '東京ウイスキー＆スピリッツコンペティション2022にて、',
        '沼津蒸留所クラフトジン ',
        '「LAZY MASTER 〜Heavenly Rose〜」',
        '（レイジーマスター ヘブンリーローズ）が',
        '金賞を受賞致しました。<br/><br/>',
        'またSilky Citrus、Shiny Lemongrass、Smoky Goldは同じく銀賞を受賞いたしました。',
        '100mlボトル4本をギフトボックスに詰めた',
        'M4STERMIND 【Original】セットでは',
        '受賞した全4種類のクラフトジンをお楽しみいただけます。',
        'ぜひこの機会にLAZY MASTERシリーズをお試しください！',
      ],
    ],
  },
  {
    id: '22041601',
    date: '2022-04-16',
    category: 'New Product',
    title: 'LAZY MASTER ~Nutty Strawberry~発売！',
    src: require('@/assets/news/22041601.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/6257d16ac9883d7616d60895',
        title: 'オンラインストア',
      },
    ],
    contents: [
      [
        '新商品発売のお知らせです。</br>',
        '沼津蒸留所は新商品LAZY MASTER ～Nutty Strawberry～ 500mlの販売を4月23日（土）より販売開始いたします。</br></br>',
        '【LAZY MASTER】<br/>',
        '第5作目となる〜Nutty Strawberry〜は伊豆市江間で採れた苺をメインに11種類のボタニカルを使用。',
        '苺の華やかさの中に、ナッティ感をプラスするためにカカオニブやアーモンドなども香り付けに使用しています。',
        'ソーダ割りやジントニックにはカットした苺を添えていただくとより一層フルーティーな風味をお楽しみいただけます。',
        'その他のお好きなジンベースのカクテルにもご使用いただけます。ラベルはNinoJapan書下しの新しい仙人グラフィックとなります。</br></br>',
        '【商品の特徴】<br/>',
        'ソーダ割や水割りにすると、甘さと華やかさの中にほのかな青さのある、苺らしい香りが感じられるのが特徴です。',
        '後味にはカカオやアーモンドのナッティでまろやかな甘みがあり、マイルドな口当たりとなっています。',
        '原酒には沼津市内のクラフトビール醸造所「Repubrew」がクラフトジンのためだけに製造したベースアルコールを使用しています。',

        'お求めは<a href="https://flavour.jp/shops/" style="color: rgb(143, 214, 255);">近隣のお取扱い酒販店様</a>、<a href="https://numazu-distillery.stores.jp/items/6257d16ac9883d7616d60895" style="color: rgb(143, 214, 255);">オンラインストア</a>、沼津蒸留所店頭をご利用ください！',
      ],
    ],
  },
  {
    id: '22040701',
    date: '2022-04-07',
    category: 'New Product',
    title: 'LAZY MASTER M4STERMIND "Original" 100ml 4本セット発売！',
    src: require('@/assets/news/22040701.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/624e85813463e724fcb21402',
        title: 'オンラインストア',
      },
    ],
    contents: [
      [
        '新商品発売のお知らせです。</br></br>',
        '沼津蒸留所は4/7日に4種類のクラフトジンを100mlボトルに詰めたLAZY MASTER M4STERMIND "original"(レイジーマスター マスターマインド オリジナル)を発売いたします。</br>',
        'こちらの商品は昨年に数量限定で店頭と近隣酒販店のみで販売を行いましたが完売となっており、この度再入荷しオンラインストアでの販売も開始いたしました。</br></br>',
        'オリジナルの4種類はそれぞれの季節ごとに静岡県東部や伊豆半島で収穫される旬なボタニカルを使用して香り付けを行っています。',
        'ボタニカルの違いで異なる香りと味わいの仕上がりとなっていますので、4種類の飲み比べをお試しいただき、ぜひお気に入りの一本を見つけてください。</br></br>',
        'M4STERMIND "original"は100mlボトル4本をギフトボックスに詰めてのお届けとなります。',
        'お誕生日や、母の日・父の日のプレゼントなど、大切な方へのギフトにもぜひご利用ください。',
        'お求めは<a href="https://flavour.jp/shops/" style="color: rgb(143, 214, 255);">近隣のお取扱い酒販店様</a>、<a href="https://numazu-distillery.stores.jp/items/624e85813463e724fcb21402" style="color: rgb(143, 214, 255);">オンラインストア</a>、沼津蒸留所店頭をご利用ください！',
      ],
    ],
  },
  {
    id: '21112201',
    date: '2021-11-22',
    category: 'Event',
    title: 'LAZY MASTER蒸留1周年!',
    src: require('@/assets/news/21112201.png'),
    links: [
      // {
      //   url: 'https://numazu-distillery.stores.jp/items/618c70e572eb46410804017b',
      //   title: 'オンラインストア',
      // },
    ],
    contents: [
      [
        'おかげさまで2020/11/28に発売されたクラフトジンLAZY MASTERはまもなく1周年を迎えます。</br></br>',
        'THE CHAMBERでは"LAZY MASTER THE FIRST ANNIVERSARY"として11/27土曜日は13:00-21:00で営業いたします。',
        'この日はこれまでに発売された4種類のクラフトジンでつくるカクテルメニューをお楽しみいただけるほか、待望のフードメニューが解禁となります！',
        '修善寺のオーベルジュFelizがTHE CHAMBERのために仕込んだスペシャルメニューの数々をぜひお楽しみください。</br>',
        'また4種類のクラフトジンをミニボトルのセットにした新しい商品も当日の店頭にてお披露目＆ご予約受付となる予定です。',
        'ホリデーシーズンのギフトにもピッタリの、4人の仙人が集合するこちらのセットにも是非ご注目ください。</br></br>',
        '当日はたくさんの方とカンパイできることを楽しみにしています！',
      ],
    ],
  },
  {
    id: '21110901',
    date: '2021-11-09',
    category: 'New Product',
    title: 'LAZY MASTER ~Smoky Gold~ 500ml発売',
    src: require('@/assets/news/21110901.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/618c70e572eb46410804017b',
        title: 'オンラインストア',
      },
    ],
    imgSrc:
      'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg',
    contents: [
      [
        '【商品の特徴】<br/>',
        '市内で採れた金柑、',
        '金木犀、',
        '和紅茶を',
        'メインに',
        '11種類の',
        'ボタニカルを',
        '使用。',
        '甘さを',
        '引き出すために',
        '林檎や',
        'メープルなども',
        '入っています。',
        '更なる',
        '特徴としまして、',
        '国内では',
        '珍しく',
        '貯蔵段階で',
        '燻製を',
        '行い',
        'その名の',
        '通り',
        'スモーキーな',
        '香りも',
        'お愉しみ',
        'いただけます。',
        'ラベルは',
        '植物学者の',
        'イメージで',
        'NinoJapanが',
        '書下ろした',
        '新しい',
        '仙人の',
        'グラフィックと',
        'なります。',
      ],
      [
        '【使用ボタニカル】<br/>',
        'ジュニパーベリー、',
        'コリアンダー、',
        '金柑、',
        '金木犀、',
        '和紅茶、',
        'メープル、',
        '林檎、',
        'ジンジャー、',
        'スモークチップ',
        'など11種',
      ],
      [
        '【おススメの飲み方】</br>',
        '・ロック<br/>',
        '・ソーダ割<br/>',
        '・紅茶割<br/>',
      ],
    ],
  },
  {
    id: '21090101',
    date: '2021-09-01',
    category: 'New Product',
    title: 'ROSOKU MINAIボタニカルキャンドルセット発売',
    src: require('@/assets/news/21090101.jpg'),
    links: [
      { url: 'https://kinarishay.wixsite.com/kinari-sya', title: '特設サイト' },
      {
        url: 'https://numazu-distillery.stores.jp/items/61270e8520239733ba9fe98b',
        title: 'オンラインストア',
      },
    ],
    imgSrc:
      'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg',
    contents: [
      [
        'プライベートの時間の過ごし方が変化した今だからこそ、',
        'おうち時間を楽しいんで頂きたいという想いからROSOKU MINAIと沼津蒸留所がコラボレーションし誕生したSPECIAL GIFTSETです。',
        '今だからこそ、あなたとあなたの大切な人に。',
      ],
      [
        '【商品の特徴】<br/>',
        'ROSOKU MINAIが作るキャンドルにはジンを蒸留する際に使うボタニカルが閉じ込めれています。',
        '蝋が溶けていく中でこぼれ落ちるボタニカルとキャンドルの灯りを眺めながら、',
        '沼津の香りが詰め込まれたジンの香りをお楽しみください。',
        'クラフトジンLAZY MASTERシリーズの新作Shiny Lemongrass(シャイニーレモングラス）は高級感のある特別仕様のラベルとなっています。',
      ],
    ],
  },
  {
    id: '21083001',
    date: '2021-08-30',
    category: 'New Product',
    title: 'LAZY MASTER ~Shiny Lemongrass~発売',
    src: require('@/assets/news/21083001.jpg'),
    links: [
      {
        url: 'https://numazu-distillery.stores.jp/items/61298598e49433245d34171a',
        title: 'オンラインストア',
      },
    ],
    imgSrc:
      'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg',
    contents: [
      [
        '【商品の特徴】<br/>',
        '沼津蒸留所の',
        '3作目となる',
        'レイジーマスター',
        '〜シャイニーレモングラス〜',
        'はボタニカルに',
        '西伊豆の',
        '松崎町で',
        '栽培された',
        'オーガニック',
        'レモングラス、',
        '自家栽培の',
        'キューバミント',
        '（イエルバブエナ）と',
        'ローズマリーなどを',
        '使用しています。',
        '国産のレモンピールも',
        '加えることで',
        'さらにシトラス感を強め、',
        '暑い夏にぴったりの',
        'スッキリした',
        '飲み口に仕上がっています。',
      ],
      [
        ' 【おススメの飲み方】<br/>',
        '・ロック<br/>',
        '・ソーダ、トニック<br/>',
        '・グレープフルーツジュース割りなど<br/>',
      ],
    ],
  },
]
export default news
