<template>
  <section
    :style="strStyle"
  >
    <slot></slot>
    <div style="height: 100px" />
    <flavour-buy-gin />
  </section>
</template>

<script>
import FlavourBuyGin from '@/components/FlavourBuyGin.vue'
export default {
  components: { FlavourBuyGin },
  props:{
    strStyle:{
      type:String,
      require:false
    }
  }
}
</script>
