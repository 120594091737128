<template>
  <v-container>
    <v-row class="rowClass justify-space-between py-5 columuns">
      <v-col
        v-for="column in links"
        :key="column.id"
        cols="6"
        md="3"
        class="text-left"
        :class="column.class"
      >
        <ul
          class="groupClass mb-5"
          v-for="group in column.groups"
          :key="group.type"
        >
          <li>
            {{ group.type }}
            <ul class="listItems">
              <li v-for="page in group.pages" :key="page.name">
                <component
                  :is="page.to ? 'router-link' : 'a'"
                  class="pageClass"
                  :to="page.to"
                  :href="page.url"
                  :target="page.url ? '_blank' : '_self'"
                  >{{ page.name }}
                  <v-icon v-if="page.url" x-small
                    >mdi-open-in-new</v-icon
                  ></component
                >
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          column: 0,
          // class: 'float-left float-sm-none d-sm-flex pb-10',
          groups: [
            {
              type: 'Contents',
              pages: [
                {
                  name: 'HOME',
                  to: '/',
                },
                {
                  name: 'LAZY MASTER',
                  to: '/lazymaster',
                },
                {
                  name: 'RECIPE',
                  to: '/recipe',
                },
                {
                  name: 'THE CHAMBER',
                  to: '/thechamber',
                },
                {
                  name: 'NUMAZU DISTILLERY',
                  to: '/numazudistillery',
                },
                {
                  name: 'WHOLESALE',
                  to: '/wholesale',
                },
                {
                  name: 'CONTACT',
                  to: '/contact',
                },
                {
                  name: 'SHOPS',
                  to: '/shops',
                },
              ],
            },
          ],
        },
        {
          column: 1,
          // class: 'float-left float-sm-none d-sm-flex ',
          groups: [
            {
              type: 'Online',
              pages: [
                {
                  name: 'STORES',
                  url: 'https://numazu-distillery.stores.jp',
                },
              ],
            },
            {
              type: 'Hometown Tax',
              direction: 'd-flex justify-center',
              pages: [
                {
                  name: 'さとふる',
                  url: 'https://www.satofull.jp/products/list.php?q=%E6%B2%BC%E6%B4%A5%E8%92%B8%E7%95%99%E6%89%80&cnt=60&p=1',
                },
                {
                  name: 'ふるぽ',
                  url: 'https://furu-po.com/goods_list.php?events=search&%E3%82%AD%E3%83%BC%E3%83%AF%E3%83%BC%E3%83%89=%E6%B2%BC%E6%B4%A5%E8%92%B8%E7%95%99%E6%89%80&%E6%A4%9C%E7%B4%A2=%E6%A4%9C%E7%B4%A2',
                },
              ],
            },
          ],
        },
        {
          column: 2,
          // class: 'float-left float-sm-none d-sm-flex ',
          groups: [
            {
              type: 'Site Info',
              pages: [
                {
                  name: 'プライバシーポリシー',
                  to: '/plivacypolicy',
                },
                {
                  name: '利用規約',
                  to: '/terms',
                },
                {
                  name: '会社情報',
                  to: '/company',
                },
              ],
            },
          ],
        },
      ],
    }
  },
}
</script>
<style scoped>
.columns {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.listItems {
  list-style: disc;
}
.pageClass {
  font-size: 0.7rem;
  font-weight: 100;
}
.groupClass {
  list-style: none;
}
</style>
