import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
import Home from '../views/Home.vue'
import Update from '../views/Update.vue'
import News from '../views/News.vue'
import NewsTheChamber from '../views/NewsTheChamber.vue'
import NotFound from '../views/NotFound.vue'
import store from '../store'
// import VueGtag from 'vue-gtag'

// Vue.use(VueGtag, { config: { id: 'G-F0Q8Y1MKKQ' } }, VueRouter)
Vue.use(VueRouter)

const pageTitle = 'クラフトジン LAZY MASTER - 沼津蒸留所 NUMAZU DISTILLERY'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Top | ' + pageTitle },
  },
  {
    path: '/lazymaster',
    name: 'LAZY MASTER',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LazyMaster.vue'),
    meta: { title: 'LAZY MASTER | ' + pageTitle },
  },
  {
    path: '/recipe',
    name: 'RECIPE',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Recipe.vue'),
    meta: { title: 'Recipe | ' + pageTitle },
  },
  {
    path: '/thechamber',
    name: 'THE CHAMBER',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/TheChamber.vue'),
    meta: { title: 'THE CHAMBER | ' + pageTitle },
  },
  {
    path: '/numazudistillery',
    name: 'NUMAZU DISTILLERY',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/NumazuDistillery.vue'),
    meta: { title: 'NUMAZU DISTILLERY | ' + pageTitle },
  },
  {
    path: '/wholesale',
    name: 'WHOLESALE',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Wholesale.vue'),
    meta: { title: 'Wholesale | ' + pageTitle },
  },
  {
    path: '/contact',
    name: 'CONTACT',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: { title: 'Contact | ' + pageTitle },
  },
  {
    path: '/thanks',
    name: 'THANKS',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Thanks.vue'),
    meta: { title: 'Thanks | ' + pageTitle },
  },
  {
    path: '/shops',
    name: 'SHOPS',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Shops.vue'),
    meta: { title: 'Shops | ' + pageTitle },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: { title: 'Contact | ' + pageTitle },
  },
  {
    path: '/folklore',
    name: 'Folklore',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LazyMasterFolklore.vue'),
    meta: { title: 'Folklore | ' + pageTitle },
  },
  {
    path: '/terms',
    name: 'TERM',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Terms.vue'),
    meta: { title: 'Terms of Service | ' + pageTitle },
  },
  {
    path: '/plivacypolicy',
    name: 'PLIVACY POLICY',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PlivacyPolicy.vue'),
    meta: { title: 'Plivacy Policy | ' + pageTitle },
  },
  {
    path: '/company',
    name: 'COMPANY',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Company.vue'),
    meta: { title: 'Company | ' + pageTitle },
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Signin.vue'),
    meta: { title: 'Signin | ' + pageTitle },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: { title: 'Dashboard | ' + pageTitle, requiresAuth: true },
  },
  { path: '/update/:id', component: Update, redirect: '/news/22041601' },
  { path: '/news/:id', component: News },
  { path: '/newsTheChamber/:id', component: NewsTheChamber },
  { path: '/pastnews/', component: ()=>import('../views/PastNews.vue') },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: '404 PageNot Found | ' + pageTitle },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
    import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    meta: { title: 'admin | ' + pageTitle },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosiion) {
    if (savedPosiion) {
      return savedPosiion
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// 認証状態をチェック
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        store.commit('setAuth', { authUser: user })
        next()
      } else {
        // 認証されていない場合
        next({ path: 'signin' })
      }
    })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  // gtag('config', 'G-F0Q8Y1MKKQ', { page_path: to.path })
  // console.debug("after!")
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
