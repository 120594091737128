<template>
  <v-footer dark padless>
    <v-card
      flat
      tile
      width="100%"
      class="grey darken-4 white--text text-center py-20"
    >
      <v-card-text class="white--text pt-0 text-center">
        <sitemap />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="white--text copyClass">
        Copyright {{ new Date().getFullYear() }} — FLAVOUR Ltd. All Rights
        Reserved.
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import Sitemap from '@/components/Sitemap.vue'
export default {
  components: { Sitemap },
  data: () => ({
    sites: [
      {
        icon: 'mdi-instagram',
        name: 'instagram',
        url: 'https://www.instagram.com/numazu_distillery',
      },
      {
        icon: 'mdi-facebook',
        name: 'facebook',
        url: 'https://www.facebook.com/FLAVOURltd',
      },
      {
        icon: 'mdi-twitter',
        name: 'twitter',
        url: 'https://twitter.com/flavour_Ltd',
      },
    ],
  }),
}
</script>

<style scoped>
/* a {
  text-decoration: none;
  color: white !important;
}

a:hover{
    color: rgb(143, 214, 255) !important;
} */
.copyClass {
  font-size: 0.5rem;
}
</style>
