<template>
  <v-app id="page_contents">
    <flavour-header />
    <transition mode="out-in">
      <router-view />
    </transition>
    <flavour-branding></flavour-branding>
    <flavour-footer2 />
    <back-to-top-button></back-to-top-button>
  </v-app>
</template>

<script>
import FlavourHeader from '@/components/FlavourHeader'
import FlavourFooter2 from './components/FlavourFooter2.vue'
import FlavourBranding from '@/components/FlavourBranding.vue'
import BackToTopButton from '@/components/BackToTopButton.vue'
export default {
  name: 'App',
  components: {
    FlavourHeader,
    FlavourFooter2,
    FlavourBranding,
    BackToTopButton,
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Klee+One&family=Noto+Sans+JP:wght@100;300;600;800&display=swap');
#page_contents {
  background-color: rgb(32, 32, 32);
  font-family: 'Noto Sans JP', sans-serif;
}
#page_contents a {
  text-decoration: none;
  color: white;
}
#page_contents a:hover {
  color: rgb(143, 214, 255);
}
.nowrap span {
  display: inline-block;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
