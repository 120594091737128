<template>
  <v-btn
    v-if="scY > 150"
    elevation="5"
    fab
    raised
    rounded
    small
    fixed
    bottom
    right
    @click="toTop"
    style="z-index: 100"
    ><v-icon color="black">mdi-arrow-up</v-icon></v-btn
  >
</template>
<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
