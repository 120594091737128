<template>
  <v-container fluid class="grey darken-3 text-center ma-0 pa-0">
    <v-container>
      <v-row style="margin: 80px 0px">
        <v-col cols="12" class="white--text title-font">
          クラフトジン<br class="d-sm-none" />”LAZY MASTER”の<br />お求めはこちらから
        </v-col>
      </v-row>
      <v-row
        v-for="type in shopList"
        :key="type.title"
        class="justify-center"
        style="margin: 50px 0px"
      >
        <section-title
          :title="type.title"
          :subTitle="type.jp_title"
        ></section-title>
        <shop-link
          v-for="shop in type.shops"
          :key="shop.name"
          :to="shop.to"
          :url="shop.url"
          :name="shop.name"
          :icon="shop.icon"
        ></shop-link>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ShopLink from '@/components/ShopLink.vue'
import SectionTitle from '@/components/SectionTitle.vue'
export default {
  components: {
    ShopLink,
    SectionTitle,
  },
  data() {
    return {
      shopList: [
        {
          title: 'Online',
          jp_title: 'オンラインストア',
          shops: [
            {
              name: 'オンラインストア',
              url: 'https://numazu-distillery.stores.jp',
              icon: 'mdi-cart-minus',
            },
          ],
        },
        {
          title: 'Shops',
          jp_title: 'お取扱店舗',
          shops: [
            {
              name: '沼津蒸留所',
              to: 'NumazuDistillery',
              icon: 'mdi-home-roof',
            },
            {
              name: 'お取扱店舗',
              to: 'Shops',
              icon: 'mdi-store-outline',
            },
          ],
        },
        {
          title: 'Hometown',
          jp_title: 'ふるさと納税',
          shops: [
            {
              name: 'さとふる',
              url: 'https://www.satofull.jp/products/list.php?q=%E6%B2%BC%E6%B4%A5+%E3%82%AF%E3%83%A9%E3%83%95%E3%83%88%E3%82%B8%E3%83%B3&cnt=60&p=1',
              icon: 'mdi-image-filter-hdr',
            },
            {
              name: '楽天ふるさと納税',
              url: 'https://search.rakuten.co.jp/search/event/%E6%B2%BC%E6%B4%A5%E8%92%B8%E7%95%99%E6%89%80/?ev=40&v=2',
              icon: 'mdi-image-filter-hdr',
            },
            {
              name: 'ふるさとチョイス',
              url: 'https://www.furusato-tax.jp/search?q=%E6%B2%BC%E6%B4%A5%E8%92%B8%E7%95%99%E6%89%80&header=1&target=1&sst=',
              icon: 'mdi-image-filter-hdr',
            },
            {
              name: 'ふるぽ',
              url: 'https://furu-po.com/goods_list.php?events=search&%E3%82%AD%E3%83%BC%E3%83%AF%E3%83%BC%E3%83%89=%E6%B2%BC%E6%B4%A5%E8%92%B8%E7%95%99%E6%89%80&%E6%A4%9C%E7%B4%A2=%E6%A4%9C%E7%B4%A2',
              icon: 'mdi-image-filter-hdr',
            },
          ],
        },
      ],
    }
  },
}
</script>
<style scoped>
.title-font {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: serif;
}
</style>
