<template>
  <v-col class="boxContainer mb-5" cols="12">
    <div class="box2">
      <div class="titleClass" :style="'font-size:' + fontSize + ';'">
        {{ title }}
      </div>
    </div>
    <div class="box1">
      <div class="subTitleClass">{{ subTitle }}</div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: false,
      default: '5rem',
    },
  },
  created() {
    console.log(this.fontSize)
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Qwigley&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Klee+One&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Kaisei+Decol&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Domine&display=swap'); */
.boxContainer {
  position: relative;
}
.box1 {
  position: absolute;
  text-align: center;
  top: 20px;
  left: 50%;
  z-index: 91;
  transform: translate(-55%, 0);
}
.box2 {
  position: absolute;
  text-align: center;
  bottom: 20px;
  left: 50%;
  z-index: 90;
  transform: rotate(-8deg) translate(-52%, 0);
}
.titleClass {
  text-align: center;
  line-height: 2rem;
  width: 320px;
  font-family: 'Qwigley', cursive;
  color: white;
}
.subTitleClass {
  font-size: 0.6rem;
  line-height: 0.6rem;
  color: lightgray;
  transform: translate(30px, 8px);
}
</style>
