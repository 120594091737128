<template>
  <default-page>
    <section-frame title="Information" subTitle="最新情報" bBack class="mt-6">
      <home-news-article :article="showNews"></home-news-article>
      <v-btn color="white" class="ml-2 mt-1" rounded small outlined @click="$router.go(-1)"
        >back</v-btn
      >
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame'
import news from '@/assets/data/News.js'
import HomeNewsArticle from '@/components/HomeNewsAirticle.vue'
export default {
  components: {
    DefaultPage,
    SectionFrame,
    HomeNewsArticle,
  },
  computed: {
    showNews() {
      return news[this.$route.params.id]
    },
  },
}
</script>
