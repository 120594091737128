<template>
  <v-row align-content="center" justify="center">
    <v-col cols="12" style="margin: 100px auto  1rem;">
      <vue-loading
        type="bars"
        color="#cccccc"
        :size="{ width: '30px', height: '30px' }"
      ></vue-loading>
    </v-col>
    <v-col cols="12" class="mb-10"><slot>now loading...</slot></v-col>
  </v-row>
</template>
<script>
import { VueLoading } from 'vue-loading-template'
export default {
  name: 'app',
  components: {
    VueLoading,
  },
}
</script>
