<template>
  <section>
    <v-container fluid class="containerClass">
      <div class="headerBG black" :style="{ opacity: fOpac }" />
      <v-row>
        <v-col cols="7" class="pl-2 pt-2 d-flex justify-start">
          <router-link to="/"
            ><v-img
              alt="NUMAZU DISTILLERY"
              class="shrink"
              contain
              min-width="60"
              src="@/assets/ND-title.png"
              width="200"
          /></router-link>
        </v-col>
        <v-col cols="5" class="pt-0 pr-0 d-flex justify-end">
          <v-btn
            depressed
            small
            color="primary"
            href="https://numazu-distillery.stores.jp"
            class="mt-2"
          >
            ジンの購入
            <v-icon small class="ml-1"> mdi-cart-outline </v-icon>
          </v-btn>
          <v-btn icon large color="white" @click.stop="drawer = !drawer">
            <v-icon class="mx-1 pt-0"> mdi-menu </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <nav-drawer :drawer="drawer" @setDrawer="drawer = $event" />
  </section>
</template>

<script>
import NavDrawer from './NavDrawer.vue'
export default {
  components: { NavDrawer },
  name: 'FlavourHeader',
  data() {
    return {
      fOpac: 0,
      drawer: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const scrollY = window.scrollY
      const fadeEnd = 150
      const maxOpac = 0.8
      this.fOpac = scrollY / fadeEnd > maxOpac ? maxOpac : scrollY / fadeEnd
    },
  },
}
</script>

<style scoped>
.containerClass {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  height: 45px;
}

.headerBG {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 45px;
}
</style>
