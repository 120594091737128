<template>
  <section>
    <video playsinline autoplay muted loop>
      <source :src="videoPath" type="video/mp4" poster="./assets/videoThumbnail.png"/>
      <!-- <source src="video/bg.webm" type="video/webm"> -->
    </video>
    <v-img
      id="top_logo"
      :src="require('../assets/NUMAZU_DISTILLERY_square_white.png')"
    />
  </section>
</template>

<script>
export default {
  name: 'HomeVideo',
  data() {
    return {
      videoPath: require('@/assets/video/top.mp4'),
    }
  },
}
</script>
<style scoped>
section {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-repeat: no-repeat;
  /* background: url('../images/bg.png') no-repeat; */
  background-size: cover;
}

#top_logo {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 60vw;
}
</style>
