<template>
  <v-navigation-drawer
    v-model="isOpen"
    right
    temporary
    class="drawerClass grey darken-2"
    width="100%"
    height="100vh"
    dark
  >
    <v-divider></v-divider>
    <v-row class="text-center" style="height: 100vh">
      <v-col cols="12" class="text-right pb-0">
        <v-btn
          class="closeClass"
          icon
          large
          color="white"
          @click.stop="isOpen = !isOpen"
        >
          <v-icon class="mx-1 pt-0"> mdi-close </v-icon>
        </v-btn>
      </v-col>
      <v-list width="100%" class="align-start py-0 my-0">
        <v-list-item
          dense
          v-for="item in items"
          :key="item.title"
          :[item.type]="item.path"
          :target="item.type === 'url' ? '_blank' : 'self'"
        >
          <v-list-item-content class="py-1">
            <v-list-item-title style="font-size: 1rem" height="100px">{{
              item.title
            }}</v-list-item-title>
            <v-list-item-subtitle style="font-size: 0.6rem">{{
              item.subtitle
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-col cols="12" v-if="auth" class="justify-center grey">
        <h3>管理者専用</h3>
        <p class="caption">{{ auth.email }}</p>
        <v-list width="100%" class="align-start py-0 my-0">
          <v-list-item dense to="/dashboard">
            <v-list-item-content class="py-1">
              <v-list-item-title style="font-size: 1rem" height="100px"
                >Dashboard</v-list-item-title
              >
              <v-list-item-subtitle style="font-size: 0.6rem"
                >ダッシュボード</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn @click="logout" dark> Log Out </v-btn>
      </v-col>
      <v-divider></v-divider>
      <v-col cols="12">
        <s-n-s-buttons></s-n-s-buttons>
      </v-col>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-col cols="12">
        <v-img
          alt="NUMAZU DISTILLERY Logo"
          contain
          src="@/assets/NUMAZU_DISTILLERY_square_white.png"
          width="100"
          style="display: inline-block"
        />
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import firebase from 'firebase'
import store from '../store'
import SNSButtons from './SNSButtons.vue'
export default {
  name: 'NavDrawer',
  components: { SNSButtons },
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fOpac: 0,
      items: [
        { title: 'HOME', subtitle: 'トップページ', path: '/', type: 'to' },
        {
          title: 'Online Store',
          subtitle: 'オンラインストア',
          path: 'https://numazu-distillery.stores.jp/',
          type: 'href',
        },
        {
          title: 'LAZY MASTER',
          subtitle: "クラフトジン 'レイジーマスター'",
          path: '/lazymaster',
          type: 'to',
        },
        {
          title: 'NUMAZU DISTILLERY',
          subtitle: '沼津蒸留所について',
          path: '/numazudistillery',
          type: 'to',
        },
        {
          title: 'THE CHAMBER',
          subtitle: 'テイスティングルーム＆カフェバー ザ・チェンバー',
          path: '/thechamber',
          type: 'to',
        },
        { title: 'SHOPS', subtitle: 'お取扱店舗', path: '/shops', type: 'to' },
        {
          title: 'WHOLESALE',
          subtitle: '商品の卸売りについて',
          path: '/wholesale',
          type: 'to',
        },
        {
          title: 'CONTACT',
          subtitle: 'お問合せ',
          path: '/contact',
          type: 'to',
        },
      ],
    }
  },
  computed: {
    auth() {
      return store.state.auth
    },
    isOpen: {
      get: function () {
        return this.drawer
      },
      set: function (v) {
        this.$emit('setDrawer', v)
      },
    },
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          store.commit('setAuth', null)
        })
        .catch((error) => {
          // An error happened.
          alert('ERROR:failed to log out!')
        })
      return
    },
  },
}
</script>

<style scoped>
.drawerClass {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
}
</style>
