<template>
  <section>
    <!-- <span style="color:white;">button</span> -->
    <div
      class="storesjp-button"
      :data-storesjp-item="dataItem"
      :data-storesjp-variation="dataVariation"
      data-storesjp-name="numazu-distillery"
      data-storesjp-layout="layout_a"
      data-storesjp-lang="ja"
    ></div>
    <component :is="'script'">
      (function (d, s, id) { var st = d.getElementsByTagName(s)[0]; if
      (d.getElementById(id)) { return; } var nst = d.createElement(s); nst.id =
      id; nst.src = "//btn.stores.jp/button.js"; nst.charset = "UTF-8";
      st.parentNode.insertBefore(nst, st); })(document, "script",
      "storesjp-button");
    </component>
  </section>
</template>
<script>
export default {
  props: {
    dataItem: { type: String, required: true },
    dataVariation: { type: String, required: true },
  },
}
</script>
