<template>
  <v-card class="mx-auto justify-center">
    <v-img height="300" :src="item.imgURL" />
    <div
      class="justify-center card-title my-3"
      v-html="item.title"
    ></div>
    <v-card-subtitle class="subtitle my-0 py-0" v-html="item.subtitle"></v-card-subtitle>
    <v-card-text class=" py-0 my-2">
      <span class="alcohol mr-2" v-html="item.alcohol"> </span> 
      <span class="volume mr-2" v-html="item.volume"> </span>
      <!-- <span class="contents" v-html="item.contents"></span><br /> -->
      <span class="price" v-html="item.price"></span>
      <!-- <span class="description" v-html="item.description"></span> -->
    </v-card-text>
    <v-card-actions class="justify-center py-2">
      <stores-button
        :dataItem="item.dataItem"
        :dataVariation="item.dataVariation"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
// import firebase from "firebase";
import StoresButton from '@/components/StoresButton.vue'

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: { StoresButton },
}
</script>
<style scoped>
.card-title {
  font-size: 1rem;
  line-height: 1rem;
}
.subtitle {
  height:2rem;
  font-size:0.8rem;
  line-height: 0.8rem;
  color: black;
}
span {
  color: black;
  font-weight: bolder;
  line-height: 0.9rem;
}
</style>
