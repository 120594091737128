import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase'
// import VueGtag from "vue-gtag";

Vue.config.productionTip = false

// Vue.use(VueGtag, {
//   config: { id: "G-F0Q8Y1MKKQ" }
// });

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCboqPQ86DmnTJcKD29iE2mUYaZDvH0soA',
  authDomain: 'flavour-vue2.firebaseapp.com',
  projectId: 'flavour-vue2',
  storageBucket: 'flavour-vue2.appspot.com',
  messagingSenderId: '661077404388',
  appId: '1:661077404388:web:f42793033065647cb81af1',
  measurementId: 'G-81BZ2YPCW4',
}
firebase.initializeApp(firebaseConfig)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
