<template>
  <section>
    <home-video />
    <!-- <information
      title="Information"
      subTitle="お知らせ"
      color="purple darken-2"
    >
      <div class="text-h4 font-weight-bold my-5">営業時間変更のお知らせ</div>
      <div>
        2023年2月1日よりTHE CHAMBERの営業時間が変更となります。<br />
        バータイムは金、土のみの営業となりますが、土日の昼間の営業がスタートします。<br />
        ご不便をおかけしますがよろしくお願いいたします。
      </div>
      <div class="text-h4 font-weight-bold" style="margin-top: 100px">
        THE CHAMBER
      </div>
      <div class="mt-3" style="font-size: 1.5rem">
        モーニング: 月―金 7:00-9:00<br />
        カフェ: 土、日 13:00-17:00<br />
        バー: 金、土 17:00-21:00<br />
      </div>
      <div class="text-h4 font-weight-bold" style="margin-top: 100px">
        THE CHAMBER LUNCH
      </div>
      <div class="mt-5" style="font-size: 1.5rem">
        ランチ: 月―木 11:00-14:00
      </div>
      <div class="mt-3" style="font-size: 0.9rem">
        ランチタイムは曜日ごとに出店者が変わります。<br />メニューの内容、ご予約などのお問い合わせは各店舗にお願いいたします。<br />詳しくは<router-link
          to="thechamber"
          >THE CHAMBER</router-link
        >をご覧ください
      </div>
    </information>
    <information
      title="Information"
      subTitle="価格改定のお知らせ"
      color="purple darken-4"
    >
      <div class="text-h4 font-weight-bold my-5">価格改定のお知らせ</div>
      <div class="text-left" style="max-width: 600px; margin: 0 auto">
        <p>
          いつも沼津蒸留所および沼津蒸留所オンラインストアをご利用いただき誠にありがとうございます。
          この度、一部商品の販売方法と価格および送料を変更させていただくことをお知らせいたします。
        </p>
        <p>
          昨今の原材料、資材およびエネルギー価格の高騰により、価格の維持が困難となりました。
          「LAZY MASTER 500mlボトル」各種と「M4STERMIND ”Original"
          4本セット」につきましては、恐れ入りますが販売価格を変更させていただきます。<br />
          また、500mlボトルはこれまで化粧箱入りのみの取り扱いでしたが、今後は別売りといたします。
        </p>

        <p>
          オンラインストアではできる限りお客様のご負担を増やさずに引き続きご利用いただけるよう、
          併せて「宅配便の送料」と「送料無料となるお買物金額」の見直しを行います。<br />
          クラフトジン500mlを1本でご注文の場合、化粧箱はつかなくなりますが、これまでよりも10円お安くお届けできるようになります。
          大変心苦しいご案内となりますが、何卒ご理解くださいますようお願いいたします。
          これからも、皆様にはより良い商品を提供できるよう努めてまいりますので、変わらぬご愛顧をお願い申し上げます。
        </p>

        <div
          class="text-h6 text-left"
          style="font-size: 0.9rem; max-width: 600px; margin: 80px auto 20px"
        >
          改訂内容
        </div>
        <dl>
          <dt>改訂時期</dt>
          <dd>2023年 3月 1日</dd>
          <dt>販売終了</dt>
          <dd>「【化粧箱入り】 LAZY MASTER 500ml」 各種 4,610円</dd>
          <dt>販売開始</dt>
          <dd>「LAZY MASTER 500ml」 各種（箱なし）4,800円</dd>
          <dd>「LAZY MASTER 500ml 共通化粧箱」 150円</dd>
          <dt>価格改定</dt>
          <dd>
            「LAZY MASTER M4STERMIND “Original” 4本セット」 4,500円 → 4,800円
          </dd>
          <dt>送料</dt>
          <dd>
            宅配便 1件につき1,000円(北海道、沖縄 1,300円)→
            800円(北海道、沖縄1,000円)
          </dd>
          <dd>
            15,000円以上のお買物で送料無料 → 10,000円以上のお買物で送料無料
          </dd>
        </dl>
      </div>
      <div
        class="text-h6 text-left"
        style="font-size: 0.9rem; max-width: 600px; margin: 80px auto 20px"
      >
        <v-btn outlined href="https://numazu-distillery.stores.jp"
          >沼津蒸留所オンラインストア</v-btn
        >
      </div>
    </information> -->
    <!-- <information title="Information" subTitle="お知らせ" color="red darken-2">
      <div>
        誠に勝手ながら、下記の期間は沼津蒸留所/THE<br class="d-md-none" />
        CHAMBERを年末年始休業とさせていただきます。
      </div>
      <div class="mt-5" style="font-size: 1.5rem">
        【休業期間】2021/12/30 ~ 2022/1/6
      </div>
      <div class="mt-3" style="font-size: 0.9rem">
        期間中は店舗営業及び酒類の販売は行いません
      </div>
      <div style="font-size: 0.9rem">
        クラフトジンのお求めはぜひ近隣のお取扱い酒販店様をご利用ください
      </div>
      <v-btn outlined to="shops">お取扱店舗</v-btn>
      <div class="mt-3" style="font-size: 0.9rem">
        休業期間中のオンラインストアのご注文につきましては
      </div>
      <div style="font-size: 0.9rem">1/5より順次発送とさせていただきます</div>
      <v-btn outlined href="http://numazu-distillery.stores.jp/" target="_top">オンラインストア</v-btn>
      <div class="mt-10" style="font-size: 1rem">
        お客様につきましてはご迷惑をおかけいたしますが、<br />何卒ご理解いただきますようお願い申し上げます。
      </div>
      <div class="mt-3" style="font-size: 1rem">
        2022年も弊社をご愛顧いただきますようお願いたします。
      </div>
      <div class="mt-3" style="font-size: 1rem">沼津蒸留所</div>
    </information> -->
    <section-frame title="Recent Feeds" subTitle="最新情報">
      <!-- <home-news></home-news> -->
      <instagram-feeds></instagram-feeds>
      <div class="mt-10 title-font">
        沼津蒸留所の最新情報は<br
          class="d-sm-none"
        />Instagramアカウントの<br />投稿とストーリーで<br
          class="d-sm-none"
        />ご覧いただけます
      </div>
      <v-btn
        outlined
        href="https://www.instagram.com/numazu_distillery"
        target="_blank"
        class="mt-5"
        >Instagram</v-btn
      >
    </section-frame>
    <!-- <section-frame title="Online Store" subTitle="オンラインストア">
      <Stores />
    </section-frame> -->
    <flavour-buy-gin />
    <!-- <instagram-feeds/> -->
  </section>
</template>

<script>
import HomeVideo from '../components/HomeVideo.vue'
import Information from '@/components/Information.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import FlavourBuyGin from '../components/FlavourBuyGin.vue'
// import HomeNews from "@/components/HomeNews.vue";
import Stores from '@/components/Stores.vue'
// import InstagramFeeds from '@/components/InstagramFeeds.vue'
import InstagramFeeds from '@/components/InstagramFeeds.vue'

export default {
  name: 'Home',
  components: {
    HomeVideo,
    Information,
    SectionFrame,
    FlavourBuyGin,
    // HomeNews,
    InstagramFeeds,
    Stores,
    // InstagramFeeds
  },
}
</script>
<style scoped>
.title-font {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: serif;
}
dl {
  margin: 20px auto;
}
dt {
  margin: 20px auto 10px;
  font-weight: bold;
}
</style>
