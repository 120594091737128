<template>
  <section>
    <!-- {{ res }} -->
    <v-divider dark />
    <v-card-text class="white--text text-left mt-2" style="line-hight: 1.5">
      <v-row class="d-flex flex-no-wrap justify-space-between">
        <v-col cols="12" md="5">
          <v-img :src="src_url" width="100%"></v-img>
        </v-col>
        <v-col cols="12" md="7">
          <span class="time-stamp" v-html="timestamp" /><br />
          <p>
            <span class="user-name" v-html="username" /><br />
            <span v-html="caption" />
          </p>
          <v-btn outlined :href="permalink" target="_blank" class="my-5"
            >Instagramで表示</v-btn
          >
          <br /><a
            href="https://twitter.com/share?ref_src=twsrc%5Etfw"
            class="twitter-share-button"
            :data-text="'【沼津蒸留所】' + shortTitle"
            :data-url="'https://flavour.jp/newsTheChamber/' + id"
            data-hashtags="クラフトジン,LAZYMASTER,沼津蒸留所"
            data-lang="ja"
            data-show-count="false"
            >Tweet</a
          >&nbsp;
          <iframe
            :src="
              'https://www.facebook.com/plugins/share_button.php?href=https://flavour.jp/newsTheChamber/' +
              id +
              '&layout=button&size=small&appId=362936122034537&width=69&height=20'
            "
            width="90"
            height="20"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
          <br /><br />
        </v-col>
      </v-row>
    </v-card-text>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/ja'

export default {
  data() {
    return {
      res: null,
    }
  },
  computed: {
    src_url() {
      if (this.res) {
        return this.res.media_type === 'VIDEO'
          ? this.res.thumbnail_url
          : this.res.media_url
      } else return null
    },
    caption() {
      var cap = ''
      if (this.res) {
        cap = this.res.caption?.replace(/(\r\n|\r|\n)/gm, '</br>')
      }
      return cap
    },
    permalink() {
      return this.res ? this.res.permalink : ''
    },
    timestamp() {
      return this.res ? moment(this.res.timestamp).fromNow() : ''
    },
    username() {
      return this.res ? this.res.username : ''
    },
    shortTitle() {
      var title = this.res.caption
      var length = 15
      var ommision = '...'
      if (title?.length <= length) {
        return title
      }
      console.log(title.substring(0, length) + ommision)
      return title.substring(0, length) + ommision
    },
  },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  async mounted() {
    //instagram get
    await axios
    .get('https://flavour.jp/insta/theChamberGetPost.php?id=' + this.id)
    .then((response) => {
      this.res = response.data
        // console.log(response)
        // response.data.data.forEach((d) => {
          //   d.src_url = 'thumbnail_url' in d ? d.thumbnail_url : d.media_url
          //   this.res.push(d)
        //})
        // console.log(response.data)
        // console.log(response.status) // 200
        // OGP設定 //23・０１現在 twitterのbotはjavascriptを読み込まない
        // let scriptOGP = document.createElement('meta')
        // scriptOGP.setAttribute('property', 'og:image')
        // scriptOGP.setAttribute('content', this.src_url)
        // document.head.appendChild(scriptOGP)
      })
      console.log("***res:",this.res)
      
    //twitter link script
    let scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    scriptEl.setAttribute('charset', 'utf-8')
    document.head.appendChild(scriptEl)
  },
}
</script>

<style scoped>
.nowrap span {
  white-space: nowrap;
  line-height: 1.7rem;
}
.link {
  text-decoration: underline !important;
  font-size: 0.9rem;
  vertical-align: baseline !important;
}
</style>
<style scoped>
.top-user-name {
  color: #999999;
  font-size: 0.7rem;
  line-height: 0.5rem;
}
.time-stamp {
  color: #999999;
  font-size: 0.7rem;
  line-height: 0.5rem;
}
.user-name {
  font-weight: bold;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 0.68rem;
}
</style>
