import { render, staticRenderFns } from "./SectionFrame.vue?vue&type=template&id=9b5be262&scoped=true"
import script from "./SectionFrame.vue?vue&type=script&lang=js"
export * from "./SectionFrame.vue?vue&type=script&lang=js"
import style0 from "./SectionFrame.vue?vue&type=style&index=0&id=9b5be262&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b5be262",
  null
  
)

export default component.exports