<template>
  <v-row>
    <v-col
      v-for="(article, index) in itemsWithImg"
      :key="index"
      cols="12"
      sm="6"
      xl="3"
    >
      <StoresCard :item="itemsWithImg[index]" />
    </v-col>
    <v-col cols="12">
      <stores-link></stores-link>
    </v-col>
  </v-row>
</template>
<script>
import firebase from 'firebase'
import StoresCard from '@/components/StoresCard.vue'
import StoresLink from '@/components/StoresLink.vue'

export default {
  components: {
    StoresCard,
    StoresLink,
  },
  data() {
    return {
      items: [
        {
          title: 'LAZY MASTER ~Lushly Green~',
          subtitle:
            'レイジーマスター ラシュリーグリーン<br/>&nbsp;',
          alcohol: '42% ',
          volume: '500ml',
          price: '&yen;4610',
          description: '',
          dataItem: '6385aa4e18324e2b0d582f31',
          dataVariation: '6385aa4e18324e2b0d582f32',
          imgName: 'LMLG500.jpg',
          imgURL: '',
          showTopPage: true,
        },
        {
          title: 'LAZY MASTER ~Heavenly Rose~',
          subtitle: 'レイジーマスター ヘブンリーローズ<br/>&nbsp;',
          alcohol: '42% ',
          volume: '500ml',
          price: '&yen;4610',
          description: 'TWSC2022金賞受賞',
          dataItem: '60a8a3bf1945c7184ddd1a52',
          dataVariation: '60a8a3bf1945c7184ddd1a53',
          imgName: 'LMHR500TWSC.jpg',
          imgURL: '',
          showTopPage: true,
        },
        {
          title: 'LAZY MASTER M4STERMIND Original Set ',
          subtitle:
            'レイジーマスター <br/>マスターマインド オリジナル4本セット',
          alcohol: '42% ',
          volume: '100ml x4',
          price: '&yen;4500',
          description: 'TWSC2022金賞・銀賞受賞',
          dataItem: '624e85813463e724fcb21402',
          dataVariation: '624e85813463e724fcb21403',
          imgName: 'M4STERMIND.jpg',
          imgURL: '',
          showTopPage: true,
        },
        {
          title: 'LAZY MASTER ~Silky Citrus~',
          subtitle: 'レイジーマスター シルキーシトラス<br/>&nbsp;',
          alcohol: '42% ',
          volume: '500ml',
          price: '&yen;4610',
          description: 'TWSC銀賞受賞',
          dataItem: '5fcdd0a7da019c10e6187af3',
          dataVariation: '5fcdd0a7da019c10e6187af5',
          imgName: 'LMSC500TWSC.jpg',
          imgURL: '',
          showTopPage: true,
        },
      ],
    }
  },
  created() {
    this.items.forEach((item) => this.getFilePath(item))
  },
  computed: {
    itemsWithImg: function () {
      var array = []
      this.items.forEach((item) => {
        if (item.imgURL === '') this.getFilePath(item)
        array.push(item)
      })
      return array
    },
  },
  methods: {
    getFilePath: function (item) {
      var str = item.imgName
      //Firabase Storageから画像を取得
      var storageRef = firebase.storage().ref()
      var imagesRef = storageRef.child('images')
      imagesRef
        .child(str)
        .getDownloadURL()
        .then((url) => {
          // console.log("URL is HERE:", url);
          item.imgURL = url
        })
        .catch((error) => alert(error))

      return 0
    },
  },
}
</script>
