<template>
  <v-container fluid>
    <v-row  dark class="border" :class="color"  style="padding:100px 0px;">
      <section-title class="mt-10" :title="title" :subTitle="subTitle"/>
      <v-col cols="12" class="mt-5 pb-16 white--text text-center align-center ">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue'
export default {
  components:{
    SectionTitle
  },
  props:{
    title:{
      type:String,
      required:true,
    },
    subTitle:{
      type:String,
      required:true,
    },
    color:{
      type:String,
      required:false,
      default:"grey"
    }
  }
}
</script>

<style scoped>

</style>