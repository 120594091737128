<template>
  <div align="center" class="text-center my-10">
    <v-img
      class="mx-auto my-5"
      width="200PX"
      src="@/assets/NUMAZU_DISTILLERY_square_white.png"
    ></v-img>
    <s-n-s-buttons></s-n-s-buttons>
    <!-- <span class="small2">Craft Gin</span><br />
    <span class="small1">Handmade in small batches</span><br />
    <span class="small1">沼津蒸留所</span><br /> -->
  </div>
</template>
<script>
import SNSButtons from './SNSButtons.vue'
export default {
  components: { SNSButtons },
}
</script>

<style scoped>
span {
  color: white;
}
.small1 {
  font-size: 0.8rem;
  line-height: 0.8rem;
}
.small2 {
  font-size: 1rem;
}
</style>
