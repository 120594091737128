<template>
  <section>
    <v-btn v-for="site in sites" :key="site.name" class="mx-4 white--text" icon
      ><a :href="site.url">
        <v-icon size="30px">
          {{ site.icon }}
        </v-icon></a
      >
    </v-btn>
  </section>
</template>

<script>
export default {
  data() {
    return {
      sites: [
        {
          icon: 'mdi-instagram',
          name: 'instagram',
          url: 'https://www.instagram.com/numazu_distillery',
        },
        {
          icon: 'mdi-facebook',
          name: 'facebook',
          url: 'https://www.facebook.com/FLAVOURltd',
        },
        {
          icon: 'mdi-twitter',
          name: 'twitter',
          url: 'https://twitter.com/flavour_Ltd',
        },
      ],
    }
  },
}
</script>
