<template>
  <v-col cols="12" md="3" class="px-2">
    <v-btn
      block
      large
      outlined
      :href="url"
      :to="to"
      :target="url ? '_blank' : '_self'"
      class="py-1"
    >
      <span style="font-size: 1rem">{{ name }}</span>
      <v-icon class="ml-1 mt-1" small> {{ icon }} </v-icon>
    </v-btn>
  </v-col>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
