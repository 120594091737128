import { render, staticRenderFns } from "./HomeNewsAirticle.vue?vue&type=template&id=128f1300&scoped=true"
import script from "./HomeNewsAirticle.vue?vue&type=script&lang=js"
export * from "./HomeNewsAirticle.vue?vue&type=script&lang=js"
import style0 from "./HomeNewsAirticle.vue?vue&type=style&index=0&id=128f1300&prod&scoped=true&lang=css"
import style1 from "./HomeNewsAirticle.vue?vue&type=style&index=1&id=128f1300&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128f1300",
  null
  
)

export default component.exports